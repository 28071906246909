import $http from '../utils/http';

/*
  $http (url, data, method, header)
*/

const BASE_URL = '/api/businesses';

/**
 * Create a new business review.
 * @param {string} token - Authorization token.
 * @param {string} businessID - ID of the business to review.
 * @param {Object} data - Review data containing rating and comment.
 * @returns {Promise<AxiosResponse>} - Promise resolving to the created review.
 */
const createBusinessReview = (token, businessID, data) => {
  const header = token ? { authorization: token } : null;
  return $http(`${BASE_URL}/${businessID}/reviews`, data, 'POST', header);
};

/**
 * Fetch all reviews for a specific business.
 * @param {string} token - Authorization token.
 * @param {string} businessID - ID of the business.
 * @returns {Promise<AxiosResponse>} - Promise resolving to the list of reviews.
 */
const getBusinessReviews = (token, businessID) => {
  const header = token ? { authorization: token } : null;
  return $http(`${BASE_URL}/${businessID}/reviews`, null, 'GET', header);
};

/**
 * Fetch a specific review by its ID for a specific business.
 * @param {string} token - Authorization token.
 * @param {string} businessID - ID of the business.
 * @param {string} reviewID - ID of the review.
 * @returns {Promise<AxiosResponse>} - Promise resolving to the review details.
 */
const getBusinessReviewByID = (token, businessID, reviewID) => {
  const header = token ? { authorization: token } : null;
  return $http(`${BASE_URL}/${businessID}/reviews/${reviewID}`, null, 'GET', header);
};

/**
 * Update a specific review's comment.
 * @param {string} token - Authorization token.
 * @param {string} businessID - ID of the business.
 * @param {string} reviewID - ID of the review.
 * @param {Object} data - Data containing the updated comment.
 * @returns {Promise<AxiosResponse>} - Promise resolving to the updated review.
 */
const updateBusinessReview = (token, businessID, reviewID, data) => {
  const header = token ? { 'Content-Type': 'application/json', authorization: token } : { 'Content-Type': 'application/json' };
  return $http(`${BASE_URL}/${businessID}/reviews/${reviewID}`, data, 'PUT', header);
};

/**
 * Delete a specific review.
 * @param {string} token - Authorization token.
 * @param {string} businessID - ID of the business.
 * @param {string} reviewID - ID of the review to delete.
 * @returns {Promise<AxiosResponse>} - Promise resolving to the deletion confirmation.
 */
const deleteBusinessReview = (token, businessID, reviewID) => {
  const header = token ? { authorization: token } : null;
  return $http(`${BASE_URL}/${businessID}/reviews/${reviewID}`, null, 'DELETE', header);
};

/**
 * Add a comment to a specific business review.
 * @param {string} token - Authorization token.
 * @param {string} businessID - ID of the business.
 * @param {string} reviewID - ID of the review to comment on.
 * @param {string} comment - The comment text.
 * @returns {Promise<AxiosResponse>} - Promise resolving to the updated review with the new comment.
 */
const addBusinessReviewComment = (token, businessID, reviewID, comment) => {
  const header = token ? { 'Content-Type': 'application/json', authorization: token } : { 'Content-Type': 'application/json' };
  console.log(
    `[addBusinessReviewComment] businessID: ${businessID}, reviewID: ${reviewID}, data: ${comment}, url: ${`${BASE_URL}/${businessID}/reviews/${reviewID}/comments`}`
  );
  return $http(`${BASE_URL}/${businessID}/reviews/${reviewID}/comments`, { comment: comment }, 'POST', header);
};

/**
 * Delete a specific review comment.
 * @param {string} token - Authorization token.
 * @param {string} businessID - ID of the business.
 * @param {string} reviewID - ID of the review.
 * @param {string} commentID - ID of the comment to delete.
 * @returns {Promise<AxiosResponse>} - Promise resolving to the deletion confirmation.
 */
const deleteBusinessReviewComment = (token, businessID, reviewID, commentID) => {
  const header = token ? { authorization: token } : null;
  return $http(`${BASE_URL}/${businessID}/reviews/${reviewID}/comments/${commentID}`, null, 'DELETE', header);
};

export {
  createBusinessReview,
  getBusinessReviews,
  getBusinessReviewByID,
  updateBusinessReview,
  deleteBusinessReview,
  addBusinessReviewComment,
  deleteBusinessReviewComment,
};
