<template>
  <div class="star-rating">
    <i v-for="n in maxStars" :key="n" :class="n <= rating ? 'fas fa-star' : 'far fa-star'" aria-hidden="true"></i>
  </div>
</template>

<script>
export default {
  name: 'StarRating',
  props: {
    rating: {
      type: Number,
      required: true,
      validator(value) {
        return value >= 0 && value <= 5;
      },
    },
    maxStars: {
      type: Number,
      default: 5,
      validator(value) {
        return value > 0;
      },
    },
  },
};
</script>

<style scoped>
.star-rating {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.star-rating i {
  color: #ffd700; /* Gold color for stars */
  margin-right: 4px; /* Spacing between stars */
  font-size: 1.5em; /* Size of stars */
}
</style>
