<template>
  <div class="page-container">
    <div class="business-view">
      <!-- Removed Business ID Header -->

      <!-- Business Name as Page Header -->
      <h1 v-if="businessDetails" class="business-name">{{ businessDetails.name }}</h1>

      <!-- Business Long Description -->
      <p v-if="businessDetails" class="business-description">{{ businessDetails.description.long }}</p>

      <!-- Loading Indicator -->
      <div v-if="loading" class="text-center mt-4">
        <pulse-loader color="var(--pulse-loader)"></pulse-loader>
      </div>

      <!-- Error Message -->
      <div v-if="error" class="alert alert-danger mt-4">
        {{ error }}
      </div>

      <!-- Business Reviews Threads Wrapped in a Card -->
      <div v-if="!loading" class="mt-4 business-reviews-card">
        <div class="card">
          <div class="card-header">
            <h2>Business Reviews</h2>
            <StarRating :rating="businessReviews.length > 0 ? meanRating : 0" />
          </div>
          <div class="card-body">
            <!-- Display "No reviews yet" if there are no reviews -->
            <div v-if="businessReviews.length === 0" class="mt-2">
              <p>No reviews yet</p>
            </div>

            <!-- Render Reviews if they exist -->
            <div v-else>
              <div v-for="review in businessReviews" :key="review._id" class="review-thread mt-4">
                <!-- Review Container -->
                <div class="review-container">
                  <!-- Review Details -->
                  <div class="review-details">
                    <strong> {{ review.user.firstname }} {{ review.user.lastname }} </strong>
                    <small class="text-muted">{{ formatDate(review.createdAt) }}</small>
                    <br />
                    <StarRating :rating="review.rating" />
                    <br />
                    <span v-if="!review.isDeleted">{{ review.comment }}</span>
                    <span v-else class="review-text-deleted">{{ review.comment }}</span>
                  </div>

                  <!-- Comments Header with Action Toolbar -->
                  <div class="comments-header mt-5">
                    <h5>Comments</h5>
                    <div class="toolbar-icons">
                      <button
                        @click="replyToReview(review)"
                        class="btn btn-link p-0 mr-2 icon-button"
                        v-b-tooltip.hover
                        title="Reply"
                        aria-label="Reply to review">
                        <i class="fa-solid fa-reply"></i>
                      </button>
                      <button
                        v-if="!review.isDeleted"
                        @click="removeReview(review)"
                        class="btn btn-link p-0 icon-button"
                        v-b-tooltip.hover
                        title="Delete"
                        aria-label="Delete review">
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </div>
                  </div>

                  <!-- Display Responses or "No comments yet" -->
                  <div v-if="review.responses && review.responses.length" class="mt-4">
                    <ul class="list-group">
                      <li v-for="response in review.responses" :key="response._id" class="list-group-item">
                        <div class="response-header">
                          <strong class="responder-name"> {{ response.user.firstname }} {{ response.user.lastname }} </strong>
                          <small class="text-muted responder-date">
                            {{ formatDate(response.postedAt) }}
                          </small>
                          <!-- Delete Comment Button -->
                          <button
                            v-if="!response.isDeleted"
                            class="btn btn-link p-0 ml-auto icon-button"
                            @click="deleteComment(review._id, response._id)"
                            v-b-tooltip.hover
                            title="Delete"
                            aria-label="Delete">
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                        <div v-if="!response.isDeleted" class="response-comment">
                          {{ response.comment }}
                        </div>
                        <div v-else class="review-text-deleted">
                          {{ response.comment }}
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div v-else-if="!review.isReplying" class="mt-4 review-text-deleted">
                    <p>No comments yet</p>
                  </div>

                  <!-- Textbox for Adding a New Response -->
                  <div class="form-group mt-4" v-if="review.isReplying">
                    <label :for="'newResponse-' + review._id"><strong>Add a comment</strong></label>
                    <textarea
                      :id="'newResponse-' + review._id"
                      v-model.trim="review.newResponse"
                      class="form-control"
                      rows="4"
                      placeholder="Type your response here..."
                      ref="`newResponse-${review._id}`"></textarea>
                  </div>

                  <!-- Send Button -->
                  <div class="text-right mt-3" v-if="review.isReplying">
                    <button :disabled="review.newResponse.length < 1 || review.sendingResponse" @click="sendResponse(review)" class="btn btn-info send-button">
                      Send <i class="fas fa-paper-plane"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End of Business Reviews Card -->
    </div>
  </div>
</template>

<script>
import {
  getBusinessReviews,
  deleteBusinessReview,
  addBusinessReviewComment,
  getBusinessReviewByID,
  deleteBusinessReviewComment,
} from '@/api/request/businessReview';
import { getBusinessById } from '@/api/request/business';
import Cookies from 'js-cookie';
import moment from 'moment';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import { BContainer, BRow, BCol, BBadge, BButton, BCard, BCardBody, BPagination, BFormSelect } from 'bootstrap-vue';
import StarRating from '@/components/StarRating.vue';

export default {
  name: 'BusinessView',
  components: {
    PulseLoader,
    StarRating,
  },
  data() {
    return {
      businessReviews: [],
      businessDetails: null,
      meanRating: 0,
      loading: false,
      error: null,
      // Pagination and Search
      searchRequest: '',
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [10, 20, 50, 100],
      sortBy: 'createdAt',
      sortDesc: true,
    };
  },
  computed: {
    businessId() {
      return this.$route.params.businessId;
    },
  },
  methods: {
    /**
     * Fetches business details by ID and stores it in the component state.
     */
    fetchBusinessDetails() {
      const token = Cookies.get('user-token');
      const businessId = this.businessId;

      getBusinessById(token, businessId)
        .then((response) => {
          this.businessDetails = response.data.data; // Adjust according to actual response structure
          if (this.businessDetails.reviewCount > 0) {
            this.meanRating = Math.ceil(this.businessDetails.cumulativeRating / this.businessDetails.reviewCount);
          } else {
            this.meanRating = 0;
          }
        })
        .catch((err) => {
          console.error(err);
          this.$notify({
            message: 'Failed to load business details.',
            timeout: 4000,
            icon: 'fas fa-exclamation-triangle',
            type: 'danger',
          });
        });
    },
    /**
     * Fetches business reviews and stores them in the component state.
     */
    fetchBusinessReviews() {
      this.loading = true;
      this.error = null;
      const token = Cookies.get('user-token');

      getBusinessReviews(token, this.businessId)
        .then((response) => {
          this.businessReviews = response.data.data.map((review) => ({
            ...review,
            newResponse: '',
            sendingResponse: false,
            isReplying: false, // Controls reply section visibility
            isDeleted: review.isDeleted || false, // Ensure isDeleted exists
          }));
          this.totalRows = this.businessReviews.length;
          this.loading = false;

          // Calculate meanRating only if there are reviews
          if (this.businessReviews.length > 0) {
            const totalRating = this.businessReviews.reduce((acc, review) => acc + review.rating, 0);
            this.meanRating = Math.ceil(totalRating / this.businessReviews.length);
          } else {
            this.meanRating = 0;
          }
        })
        .catch((err) => {
          console.error(err);
          this.error = 'Failed to load business reviews.';
          this.loading = false;
        });
    },
    /**
     * Formats a given date string into a readable format.
     * @param {String} dateStr - The date string to format.
     * @returns {String} - Formatted date.
     */
    formatDate(dateStr) {
      return moment(dateStr).format('MMMM Do YYYY, h:mm a');
    },
    /**
     * Toggles the reply section for a given review.
     * If the section is being opened, focus the textarea.
     * @param {Object} review - The review object to reply to.
     */
    replyToReview(review) {
      // Toggle the isReplying state
      review.isReplying = !review.isReplying;

      // If the reply section is now visible, focus the textarea
      if (review.isReplying) {
        this.$nextTick(() => {
          const responseRef = this.$refs[`newResponse-${review._id}`];
          if (responseRef) {
            responseRef.focus();
          }
        });
      }
    },
    /**
     * Sends a response to a given review.
     * @param {Object} review - The review object to respond to.
     */
    sendResponse(review) {
      if (!review.newResponse.trim()) return;

      review.sendingResponse = true;
      const token = Cookies.get('user-token');

      addBusinessReviewComment(token, this.businessId, review._id, review.newResponse.trim())
        .then((response) => {
          review.responses = response.data.data.responses;
          review.newResponse = '';
          review.isReplying = false;
          review.sendingResponse = false;
        })
        .catch((err) => {
          console.error(err);
          this.$notify({
            message: 'Failed to send response.',
            timeout: 4000,
            icon: 'fas fa-exclamation-triangle',
            type: 'danger',
          });
          review.sendingResponse = false;
        });
    },
    /**
     * Removes a given review by marking it as deleted.
     * After successful deletion, re-fetch the updated review and update it on the screen.
     * @param {Object} review - The review object to remove.
     */
    removeReview(review) {
      const confirmDeletion = confirm('Are you sure you want to delete this review?');

      if (!confirmDeletion) return;

      const token = Cookies.get('user-token');

      deleteBusinessReview(token, this.businessId, review._id)
        .then(() => {
          // Re-fetch the updated review
          return getBusinessReviewByID(token, this.businessId, review._id);
        })
        .then((response) => {
          const updatedReview = response.data.data; // Adjust according to actual response structure

          // Find the index of the current review in the businessReviews array
          const index = this.businessReviews.findIndex((r) => r._id === review._id);
          if (index !== -1) {
            // Replace the old review with the updated review data
            this.$set(this.businessReviews, index, {
              ...this.businessReviews[index],
              ...updatedReview,
              // Retain existing properties that might not be returned from the API
              newResponse: this.businessReviews[index].newResponse,
              sendingResponse: this.businessReviews[index].sendingResponse,
              isReplying: this.businessReviews[index].isReplying,
            });
          }

          this.$notify({
            message: 'Review deleted successfully.',
            timeout: 3000,
            icon: 'fas fa-check-circle',
            type: 'success',
          });
        })
        .catch((err) => {
          console.error(err);
          this.$notify({
            message: 'Failed to delete and update review.',
            timeout: 4000,
            icon: 'fas fa-exclamation-triangle',
            type: 'danger',
          });
        });
    },
    /**
     * Deletes a specific comment on a review.
     * @param {string} reviewID - ID of the review.
     * @param {string} commentID - ID of the comment to delete.
     */
    deleteComment(reviewID, commentID) {
      const confirmDeletion = confirm('Are you sure you want to delete this comment?');

      if (!confirmDeletion) return;

      const token = Cookies.get('user-token');

      deleteBusinessReviewComment(token, this.businessId, reviewID, commentID)
        .then(() => {
          // Re-fetch the updated review
          return getBusinessReviewByID(token, this.businessId, reviewID);
        })
        .then((response) => {
          const updatedReview = response.data.data; // Adjust based on actual response structure

          // Find the index of the current review in the businessReviews array
          const index = this.businessReviews.findIndex((r) => r._id === reviewID);
          if (index !== -1) {
            // Replace the old review with the updated review data
            this.$set(this.businessReviews, index, {
              ...this.businessReviews[index],
              ...updatedReview,
              // Retain existing properties that might not be returned from the API
              newResponse: this.businessReviews[index].newResponse,
              sendingResponse: this.businessReviews[index].sendingResponse,
              isReplying: this.businessReviews[index].isReplying,
            });
          }

          this.$notify({
            message: 'Comment deleted successfully.',
            timeout: 3000,
            icon: 'fas fa-check-circle',
            type: 'success',
          });
        })
        .catch((err) => {
          console.error(err);
          this.$notify({
            message: 'Failed to delete comment.',
            timeout: 4000,
            icon: 'fas fa-exclamation-triangle',
            type: 'danger',
          });
        });
    },
  },
  mounted() {
    this.fetchBusinessDetails();
    this.fetchBusinessReviews();
  },
};
</script>

<style>
/* Base Styles */
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: var(--toolbar-height, 60px); /* Accounts for the top toolbar height */
}

.business-view {
  flex: 1;
  padding: 20px;
}

.business-name {
  text-align: center;
  font-size: 2em;
  margin-bottom: 10px;
}

.business-description {
  text-align: center;
  font-size: 1.2em;
  margin-bottom: 20px;
}

/* Footer Styles */
.footer {
  background-color: #f8f9fa; /* Light gray background */
  padding: 20px 0;
  text-align: center;
  border-top: 1px solid #e0e0e0;
}

.footer .container {
  width: 100%;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .review-container {
    padding: 25px;
  }

  .business-reviews-card {
    width: 65%;
    margin: 0 auto;
  }

  .footer .container {
    width: 65%;
  }
}

@media (max-width: 767.98px) {
  .business-reviews-card,
  .footer .container,
  .alert {
    width: 90%;
    margin: 0 auto;
  }
}

/* Ensuring Footer is Always at the Bottom of the Viewport */
html,
body,
.page-container {
  height: 100%;
  margin: 0;
  padding: 0;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.business-view {
  flex: 1;
}

/* Optional: Smooth Scrolling for Better UX */
html {
  scroll-behavior: smooth;
}

/* Additional Styles for Reviews and Comments */
.review-container {
  background-color: #ffffff;
  /* border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  /* padding: 15px; */
  padding: 15px 15px 40px 15px;
}

.review-details {
  display: flex;
  flex-direction: column;
}

.review-text-deleted {
  color: var(--text-muted, #b9b9b9);
  font-style: italic;
}

.comments-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem; /* Adds spacing below the header */
}

.toolbar-icons {
  display: flex; /* Ensures buttons are aligned horizontally */
  gap: 0.5rem; /* Adds space between buttons */
}

.comments-header h5 {
  margin: 0; /* Removes default margins */
  font-size: 1.25em; /* Adjusts font size as needed */
}

.text-muted {
  color: #6c757d !important;
}

/* Additional Styles for Response Header */
.response-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px; /* Optional: Adds space below the header */
}

.responder-name {
  margin-right: 15px; /* Adjust the value for desired spacing */
  font-weight: bold;
}

.responder-date {
  font-size: 0.9em;
  color: var(--text-muted, #6c757d); /* Ensures consistency with existing muted text */
}

.response-comment {
  margin-left: 25px; /* Aligns the comment with the responder's name */
}

/* Optional: Responsive Adjustments */
@media (max-width: 767.98px) {
  .comments-header {
    flex-direction: column; /* Stacks items vertically on small screens */
    align-items: flex-start; /* Aligns items to the start */
  }

  .toolbar-icons {
    margin-top: 0.5rem; /* Adds space above the toolbar on small screens */
  }

  .response-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .responder-name,
  .responder-date {
    margin-right: 0;
    margin-bottom: 4px;
  }

  .response-comment {
    margin-left: 0;
  }
}

/* Send Button Styles */
.send-button {
  padding-bottom: 8px; /* Adjust the value as needed */
  margin-bottom: 20px; /* Increased bottom margin */
}

/* Increased title size */
.modal-title-large {
  font-size: 1.25em; /* 1.25 times the default size */
}

/* Cursor pointer on hover for Send button */
.send-button:hover {
  cursor: pointer;
}

/* Additional Styles for Enhanced Business Reviews Component */
.business-reviews-card .card-body .business-reviews h2 {
  text-align: left;
  font-size: 1.8em;
  margin-bottom: 15px;
}

/* StarRating Component Alignment */
.business-reviews-card .card-body .star-rating {
  margin-bottom: 20px;
}

/* Distinct Separation Between Reviews */
.review-thread:not(:last-child) .review-container {
  border-bottom: 1px solid #eeeeee; /* Thicker border for separation */
}

/* Delete Button Styling */
.icon-button {
  color: #a6a6a6; /* Bootstrap danger color for delete */
}

.icon-button:hover {
  color: #7c7c7c; /* Darker shade on hover */
}
</style>
